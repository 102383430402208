<template>
    <b-modal
          id="change-departament"
          ref="departament"
          hide-footer
          centered
          header-class="bg-white"
      >
          <div class="d-flex flex-column align-items-center justify-content-center mb-3 text-center">
              
              <h2 class="text-center">Selecione o departamento</h2>
              <b-row class="col-12 mt-2">
                <b-col lg="12" v-for="departament in departaments" :key="departament.id_departamento">
                  <b-button
                      class="d-flex col-12 align-items-center"
                      variant="flat-dark"
                      @click="confirmAction(departament)"
                  >
                      <b-img
                        :src="require('@/assets/custom-icons/building-user-menu.svg')"
                        class="mr-3"
                        height="30"
                      />
                      <div class="d-flex flex-column justify-content-start">
                        <h3 class="text-left custom-gray">{{ departament.sigla }}</h3>
                      <span class="text-left text-secondary">{{ departament.descricao }}</span>
                      </div>
                  </b-button>
                </b-col>
              </b-row>
          </div>
      </b-modal>
  </template>
  
  <script>
  import { BModal, BButton, BLink, BRow, BCol, BImg } from 'bootstrap-vue';
  import {setDepartmentTemporaryId, setDepartmentTemporaryUf} from "@core/utils/store/setStore";

  
  export default {
    components: {
          BModal, BButton, BLink, BRow, BCol, BImg
      },
    data() {
      return {
        departaments: []
      }
    },
    mounted() {
      this.$http.get(this.$api.departamento(), {
        params: {
          todos: true
        },
      })
          .then(({ data }) => {
            this.departaments = data;
          })
    },
    methods: {
      confirmAction(departament) {
              this.$refs.departament.hide();
              this.$http.post(
                  this.$api.tokenComDepartamento(),
                  {idDepartamento: departament.id_departamento})
                  .then((token) => {
                    setDepartmentTemporaryUf(departament.sigla)
                    setDepartmentTemporaryId(departament.id_departamento)
                    this.$store.dispatch('sessions/setToken', token.data)
                    this.redirectToHome();
                  })
      },
      redirectToHome(){
        if (this.$route.name === 'home') {
          this.$router.go(0);
          return;
        }

        this.$router.push({ 
            name: 'home'
        })
      },
    },
  }
  </script>
  
  <style scoped>
      .bloco{
        height: 100px;
      }
  
      .custom-gray{
        color: #6e6b7b;
      }
      
      .list-enter-active,
      .list-leave-active {
        transition: all 0.5s ease;
      }
      .list-enter-from,
      .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
      }
  
      .panel-enter-active,
      .panel-leave-active {
        transition: all 0.5s ease;
      }
      .panel-enter-from,
      .panel-leave-to {
        opacity: 0;
        height: 0;
        transform: translateX(30px);
      }
  </style>
  