<template>
  <section v-if="userData !== null">
    <ChangeCompanyModal :companies="userCompany" v-if="changeCompany"/>
    <ChangeUnitModal v-if="changeUnit"/>
    <ChangeDepartamentModal v-if="changeDepartament"/>

    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.fullName }}

          </p>
          <span class="user-status">{{ userData.perfis[0].descricao }} <span v-if="departmentTemporaryUf">{{departmentTemporaryUf}}</span></span>
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
        </b-avatar>
      </template>
      <b-dropdown-item
        :to="{ name: 'minha-conta' }"
        link-class="d-flex align-items-center px-1"
      >
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span>Minha conta</span>
      </b-dropdown-item>
      <!-- <b-dropdown-item
        v-if="changeCompany"
        link-class="d-flex align-items-center px-1"
        v-b-modal.change-company
        @click="updateCompany"
      >
      <b-img
          :src="require('@/assets/custom-icons/building-user-menu.svg')"
          class="mr-50"
          height="16"
        />
        <span>Trocar empresa</span>
      </b-dropdown-item> -->
      <b-dropdown-item
        v-if="changeUnit"
        link-class="d-flex align-items-center px-1"
        v-b-modal.change-unit
      >
      <b-img
          :src="require('@/assets/custom-icons/building-user-menu.svg')"
          class="mr-50"
          height="16"
        />
        <span>Trocar unidade</span>
      </b-dropdown-item>
      <b-dropdown-item
          v-if="changeDepartament"
          link-class="d-flex align-items-center px-1"
          v-b-modal.change-departament
      >
        <b-img
            :src="require('@/assets/custom-icons/building-user-menu.svg')"
            class="mr-50"
            height="16"
        />
        <span>Trocar DR</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-faq' }"
        link-class="d-flex align-items-center px-1"
      >
        <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
        <span>Ajuda e suporte</span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center px-1"
        :to="{ name: 'politica-privacidade' }"
      >
        <feather-icon size="16" icon="FileTextIcon" class="mr-50" />
        <span
          >Política de<br />
          Privacidade</span
        >
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center px-1"
        @click="logout"
      >
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
      </b-dropdown-item></b-nav-item-dropdown
    >
  </section>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BAvatar, BImg } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { logoutUser,isUserLoggedIn } from "@/auth/utils";
import ChangeCompanyModal from "@/views/components/custom/modals/ChangeCompanyModal.vue";
import {actions, subjects} from "@/libs/acl/rules";
import ChangeUnitModal from "@/views/components/custom/modals/ChangeUnitModal.vue";
import ChangeDepartamentModal from "@/views/components/custom/modals/ChangeDepartamentModal.vue";
import { setUserCompanyList } from "@core/utils/store/setStore";
import {getDepartmentTemporaryUf, getUserCompanyList} from "@core/utils/store/getStore";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BImg,
    ChangeCompanyModal,
    ChangeUnitModal,
    ChangeDepartamentModal
},
  computed: {
    departmentTemporaryUf() {
      return getDepartmentTemporaryUf();
    }
  },
  data() {
    return {
      userData: null,
      userCompany: [],
      avatarText,
      changeCompany: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_EMPRESA),
      changeUnit: false,
      changeDepartament: false,
    };
  },
  mounted() {
    if (isUserLoggedIn()) {
      this.userData = this.$store.state.sessions.userData

      let multiUo = this.$can(actions.VISUALIZAR, subjects.DASHBOARD_UO) && this.userData.perfis[0].id_perfil == 11;

      this.changeUnit = this.$can(actions.VISUALIZAR, subjects.DASHBOARD_VACINADOR) || multiUo ? true : false
      this.changeDepartament = this.$can(actions.ATUALIZAR, subjects.DEPARTAMENTO_TEMPORARIO)
    }
  },
  methods: {
    logout() {
      this.$store.commit('filtrosState/setLimparState');
      setUserCompanyList([])
      logoutUser()
        .then(() => {
          this.$router.push({ name: "auth-login" });
        })
        .catch(() => {
          this.$router.push({ name: "auth-login" });
        });
    },
    updateCompany() {
      this.userCompany = getUserCompanyList()
    },

  }

};
</script>
