<template>
  <section>
    <ModalShowAviso
      :showModal="modal.showModal"
      :aviso="avisoEscolhido"
      @confirmed="closeModalAviso"
    />

    <b-nav-item-dropdown
      ref="dropMenu"
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :badge="notificacoesContadorNaoLidas"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
          @click="loadDataAvisoNaoVisualizado"
        />
      </template>
    <ul class="dropdown-menu-section">
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <span class="notification-title mb-0 mr-auto"
            >Notificações
            {{
              notificacoesContador > 0
                ? "(" + notificacoesContadorNaoLidas + " não lidas)"
                : ""
            }}
          </span>
      
        </div>
      </li>
    </ul>

  
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area pr-2"
        tagname="li"
      >

        <div v-if="loading" class="d-flex justify-content-center my-3">
          <div class="spinner-border text-custom-blue" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>


        <template v-if="!loading">

          <b-link
            v-for="aviso in notif"
            :key="aviso.id_notificacao_usuario + componentKey"
            @click.prevent="atualizaNotificacaoUsuarioComoLida(aviso)"
          >
            <b-media :class="!aviso.notificado ? 'cartao ' : ''">
              <template #aside>
                <b-avatar variant="light" size="32">
                  <feather-icon
                    class="icone"
                    size="21"
                    :icon="aviso.arquivo ? aviso.arquivo : 'AlertCircleIcon'"
                  />
                </b-avatar>
              </template>
              <b-row class="d-flex justify-content-between">
                <template
                  v-if="
                    aviso.notificacao.id_tipo_notificacao ==
                    TiposNotificacoes.AVISO
                  "
                >
                  <b-col lg="8" md="8" sm="8">
                    <p class="media-heading">
                      <span class="font-weight-bolder">
                        {{ formatTitle(aviso.notificacao.titulo) }}
                      </span>
                    </p>
                    <small class="notification-text">{{
                      formatDate(aviso.notificacao.vigencia_inicial)
                    }}</small>
                  </b-col>
                  <b-col lg="4" md="4" sm="3">
                    <b-button
                      id="view-aviso"
                      size="sm"
                      variant="outline-custom-blue"
                      @click.prevent="openModalAviso(aviso)"
                    >
                      Ver aviso
                    </b-button>
                  </b-col>
                </template>
                <template v-else>
                  <b-col lg="9" md="9" sm="9">
                    <p class="media-heading">
                      <span class="font-weight-bolder">
                        {{ formatTitle(aviso.notificacao.titulo) }}
                      </span>
                    </p>
                    <small
                      class="notification-text"
                      v-html="formataNotificacao(aviso.notificacao.conteudo)"
                    ></small>
                  </b-col>
                  <b-col lg="3" md="3" sm="3">
                    <small class="notification-text">{{
                      formatDateDias(aviso.dt_criacao)
                    }}</small>
                  </b-col>
                </template>
              </b-row>
            </b-media>
          </b-link>
        </template>
      </vue-perfect-scrollbar>


    </b-nav-item-dropdown>
  </section>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BCol,
  BRow,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import ModalShowAviso from "@/views/components/custom/modals/ModalShowAviso.vue";
import { TIPONOTIFICACAO } from "@/enum/tipo-notificacao";
import {  isUserLoggedIn } from "@/auth/utils";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    ModalShowAviso,
    BCol,
    BRow
  },
  directives: {
    Ripple,
  },
  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      TiposNotificacoes: TIPONOTIFICACAO,
      componentKey: 0,
      perfectScrollbarSettings,
      loading: false,
      avisos: [],
      avisoEscolhido: null,
      modal: {
        showModal: false,
      },
      userData: null,
    };
  },
  mounted() {
    if(isUserLoggedIn()){
     this.loadDataAvisoNaoVisualizado();
    }
  },
  computed: {
    notif: function () {
      return this.setNotificacoes();
      
    },

    notificacoesContador: {
      get: function () {
        let notifications =
          this.$store.getters["notifications/getNotificationsBell"].itens;
        return notifications != null ? notifications.length : 0;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    notificacoesContadorNaoLidas: {
      get: function () {
        let notifications =
          this.$store.getters["notifications/getNotificationsBell"].itens;
        let itens = notifications.filter(
          (notification) => !notification.notificado
        );
        return itens != null ? itens.length : 0;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  watch: {
    "$store.state.notifications.updated_at": {
      immediate: true,
      handler() {
        this.setNotificacoes();
      },
    },
    "$store.state.notifications.itens": {
      immediate: true,
      handler() {
        this.setNotificacoes();
      },
    },
  },
  computed: {
    notif: function () {
      return this.setNotificacoes();
      
    },

    notificacoesContador: {
      get: function () {
        let notifications =
          this.$store.getters["notifications/getNotificationsBell"].itens;
        return notifications != null ? notifications.length : 0;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    notificacoesContadorNaoLidas: {
      get: function () {
        let notifications =
          this.$store.getters["notifications/getNotificationsBell"].itens;
        let itens = notifications.filter(
          (notification) => !notification.notificado
        );
        return itens != null ? itens.length : 0;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  watch: {
    "$store.state.notifications.updated_at": {
      immediate: true,
      handler() {
        this.setNotificacoes();
      },
    },
    "$store.state.notifications.itens": {
      immediate: true,
      handler() {
        this.setNotificacoes();
      },
    },
  },
  methods: {
    loadDataAvisoNaoVisualizado() {
      this.$http
        .get(this.$api.getNotificacoesVigentes(), {
          params: {
            vigencia: moment().format("YYYY-MM-DD"),
          },
        })
        .then(({ data }) => {
          this.avisos = data.data;
          this.loading = false;
        });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    formatDateDias(value) {
      if (value) {
        const currentDate = new Date();
        const inputDate = new Date(value);
        const timeDiff = Math.abs(currentDate.getTime() - inputDate.getTime());
        const dias = Math.floor(timeDiff / (1000 * 3600 * 24));
        let frase;

        switch (true) {
          case dias == 0:
            frase = "Hoje";
            break;
          case dias == 1:
            frase = "Ontem";
            break;
          case dias >= 2 && dias <= 10:
            frase = "há " + dias + " dias";
            break;
          default:
            frase = "Acima de dez dias";
        }

        return frase;
      }
    },
    formatTitle(value) {
      if (value.length > 30) {
        value = value.substring(0, 30) + "...";
      }
      return value;
    },
    openModalAviso(aviso) {
      this.avisoEscolhido = aviso;
      this.modal.showModal = true;
    },

    closeModalAviso() {
      this.modal.showModal = false;
      this.avisoEscolhido = null;
    },
    formataNotificacao(notificacao) {
      return notificacao.replaceAll("\n", "<br>");
    },
    setNotificacoes() {
      let notifications = this.$store.getters["notifications/getNotifications"];
      this.componentKey = notifications.updated_at * 9;
      this.avisos = notifications.itens;
      this.loading = false;

      return notifications.itens;
    },

    atualizaNotificacaoUsuarioComoLida(value) {
      let idNotificacaoUsuario = value.id_notificacao_usuario;

      this.$store.dispatch("notifications/updateUserNotificationAsRead", {
        idNotificacaoUsuario,
      });

      this.$refs.dropMenu.hide();
      const valorDiferenciadoModificadoId = 18;
      const empresaCNAEDiferente = 3;

      if (
          (value.notificacao.id_tipo_notificacao === empresaCNAEDiferente || value.notificacao.id_tipo_notificacao === valorDiferenciadoModificadoId) &&
          value.id_empresa
      ) {
        this.$router.replace({
          name: 'empresa-edit',
          params: { idEmpresa: value.id_empresa },
        }).catch(() => {
          this.$router.push({
                name: 'home',
                params: {
                  redirectToUrl: true,
                  routeParams: {idEmpresa: value.id_empresa},
                  routeName: 'empresa-edit'
                }
              }
          )
        });
        return
      }

      if (value.url) {
        const routeName = this.$router.resolve(value.url).route.name;

        this.$router.push({
            name: routeName, 
            params: { idEmpresa: value.id_empresa },
          }
        ).catch(() => {
              this.$router.push({
                name: 'home', 
                params: { 
                  redirectToUrl: true,
                  routeParams: {idEmpresa: value.id_empresa},
                  routeName: routeName
                }
              }
            )
        });
      }
    },
  },
};
</script>

<style scoped>
.dropdown-menu-section{
  list-style-type: none;
  padding: 0;
}
.cartao{
  border-radius: 10px;
  background-color: #eaf1f7;
  margin-left :  10px;
  margin:8px;



}
.icone{
  color:#646464;
  width: 21px;
  height: 21px;}
</style>


